"use strict";

import axios from "axios";
var baseControllerAddress = 'notification/';

export default {

    getNotificationTypes: (gateUserId) => {
        return axios.get('/api/' + baseControllerAddress + "GetNotificationTypes/", { params: { gateUserId } })
            .then(result => {
                return result.data;
            }).catch(function (error) {
                alert(error);
            });
    },
    setNotificationTypes: (gateUserId, notificationTypesModels) => {
        return axios.post('/api/' + baseControllerAddress + "SetNotificationTypes/", notificationTypesModels, {params: {gateUserId}})
            .then(result => {
                return result.data;
            }).catch(function (error) {
                alert(error);
            });
    }

}