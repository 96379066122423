<template>
    <div>
        <b-row>
            <b-col sm="6" v-for="(item, i) in gateNotificationTypes" :key="i">
                <b-form-checkbox v-if="item.name !== 'Default'" v-model="gateNotificationTypes[i].isEnabled">
                    <b>{{ item.name }}</b>
                </b-form-checkbox>
            </b-col>
        </b-row>
        <hr />

        <b-row align-v="center">
            <!--<b-col>
                <b-button variant="primary"><b-icon icon="plus" class="mr-2"></b-icon>REINVITE USER</b-button>
            </b-col>-->
            <b-col class="d-flex align-items-center justify-content-end">
                <b-button @click="cancel()" variant="primary" class="mr-1">CANCEL</b-button>
                <b-button @click="updateNotificationSettings()" variant="success">SAVE</b-button>
            </b-col>
        </b-row>
    </div>
</template>

<style scoped>

</style>

<script>
import { mapState } from 'vuex';
import notificationService from "@/services/notificationService";

export default {
    props: {
        gateUser: {},
        gate: {},
    },
    data() {
        return {
            gateNotificationTypes: [
                {
                    type: 0,
                    name: 'Default',
                    isEnabled: false
                },
            ],
        }
    },
    methods: {
        cancel() {
            this.$root.$emit('bv::hide::modal', 'notifications-modal');
        },
        async updateNotificationSettings() {
            await notificationService.setNotificationTypes(this.gateUser.id, this.gateNotificationTypes.map(x => 
            {
                return {
                    type: x.type,
                    isEnabled: x.isEnabled,
                    name: x.name
                }
            }))
            this.$root.$emit('dmx::saved::gateUserNotifications');
        },
        async loadNotifications() {
            let typeModels = [...(await notificationService.getNotificationTypes(this.gateUser.id))]
            this.gateNotificationTypes = typeModels;
        }
    },
    computed: {
        ...mapState({
            stateUser: store => store.authentication.user,
        })
    },
    watch: {

    },
    mounted() {
        this.loadNotifications()
    }
}
</script>