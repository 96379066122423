"use strict";

import axios from "axios";
var baseControllerAddress = 'gate/';
// gate
export default {
    getGateList: (action, vm) => {
        return axios.post('/api/' + baseControllerAddress + action, vm)
            .then(result => {
                return result.data;
            }).catch(function (error) {
                if (error.response.status != 401) {
                    alert(error);
                }
            });
    },
    updateGate: (vm) => {
        return axios.put('/api/' + baseControllerAddress + "update", vm)
            .then(result => {
                return result.data;
            }).catch(function (error) {
                alert(error);
            });
    },
    createGate: (vm) => {
        return axios.post('/api/' + baseControllerAddress + "create", vm)
            .then(res => {
                return res.data;
            }).catch(function (error) {
                alert(error);
            });
    },
    newGate: (id) => {
        return axios.get('/api/' + baseControllerAddress + "create")
            .then(result => {
                return result.data;
            }).catch(function (error) {
                alert(error);
            });
    },
    getGateUsers: (vm) => {
        return axios.post('/api/' + baseControllerAddress + "GetGateUsers", vm)
            .then(result => {
                return result.data;
            }).catch(function (error) {
                return [];
                // alert(error);
            });
    },
    getGate: (id) => {
        return axios.get('/api/' + baseControllerAddress + "get/" + id)
            .then(result => {
                return result.data;
            }).catch(function (error) {
                alert(error);
            });
    },
    getGateHolidays: (id) => {
        return axios.get('/api/' + baseControllerAddress + "getGateHolidays?gateId=" + id)
            .then(result => {
                return result.data;
            }).catch(function (error) {
                alert(error);
            });
    },
    deleteAll: () => {
        return axios.delete('/api/' + baseControllerAddress + "deleteAllGates")
            .catch(function (error) {
                alert(error);
            });
    },
    deleteGate: (vm) => {
        return axios.post('/api/' + baseControllerAddress + "deleteGate", vm)
            .catch(function (error) {
                alert(error);
            });
    },

    getFreeGates: (id) => {
        return axios.get('/api/' + baseControllerAddress + "GetAllFreeGates/" + id)
            .then(result => {
                return result.data;
            }).catch(function (error) {
                alert(error);
            });
    },
    getAvailableGateRoles: () => {
        return axios.get('/api/' + baseControllerAddress + "GetAvalableGateRoles")
            .then(result => {
                return result.data;
            }).catch(function (error) {
                alert(error);
            });
    },
    getUserGatePermissions: () => {
        return axios.get('/api/' + baseControllerAddress + "GetUserGatePermissions")
            .then(result => {
                return result.data;
            }).catch(function (error) {
                alert(error);
            });
    },
    updateUserGatePermissions: (vm) => {
        return axios.put('/api/' + baseControllerAddress + "updateUserGatePermissions", vm)
            .then(result => {
                return result.data;
            });
    },
    changeGateModel: (vm) => {
        return axios.put('/api/' + baseControllerAddress + "ChangeGateModel", vm)
            .then(result => {
                return result.data;
            });
    },
    handOverGateOwnership: (vm) => {
        return axios.post('/api/' + baseControllerAddress + "handOverGateOwnership", vm)
            .then(result => {
                return result.data;
            }).catch(function (error) {
                alert(error);
            });
    },
    getGatePictures: (id) => {
        return axios.get('/api/' + baseControllerAddress + "getGatePictures/" + id)
            .then(result => {
                return result.data;
            }).catch(function (error) {
                alert(error);
            });
    },
    deleteGatePicture: (id) => {
        return axios.delete('/api/' + baseControllerAddress + "deleteGatePicture/" + id)
            .then(result => {
                return result.data;
            }).catch(function (error) {
                alert(error);
            });
    },
}