<template>
    <div>
        <b-row>
            <b-col sm="6" v-for="(item, i) in defaultGateUserPermissions" :key="i">
                <b-form-checkbox v-model="defaultGateUserPermissions[i].value">
                    <b>{{ item.name }}</b>
                </b-form-checkbox>
                <span class="small">
                    <i>{{item.description}}</i>
                </span>
            </b-col>
        </b-row>
        <div v-if="gateUser.gateOperationCode != 0">
            <hr />
            Current gate operation code: {{ gateUser.gateOperationCode }}
            <b-button size="sm" variant="warning" class="mt-2" @click="resetInAppAuthenticationCode()">RESET CURRENT APPLICATION CODE</b-button>
        </div>
        <hr />

        <b-row align-v="center">
            <!--<b-col>
                <b-button variant="primary"><b-icon icon="plus" class="mr-2"></b-icon>REINVITE USER</b-button>
            </b-col>-->
            <b-col class="d-flex align-items-center justify-content-end">
                <b-button @click="cancel()" variant="primary" class="mr-1">CANCEL</b-button>
                <b-button @click="updateUserGatePermissions()" variant="success">SAVE</b-button>
            </b-col>
        </b-row>
    </div>
</template>

<style scoped>
    
</style>

<script>
    import gateService from '@/services/gateService';
    import router from '@/router';
    import accountService from '@/services/accountService';
    import { UserRoles } from '@/variables/variables.js'
    import { mapState, mapActions } from 'vuex';

    export default {
        props: {
            gateUser: {},
            gate: {},
        },
        data() {
            return {
                defaultGateUserPermissions: [
                    
                    {
                        permission: 310,
                        name: 'Operate gate',
                        description: 'Be able to manually close or open gate',
                        value: false
                    },
                    {
                        permission: 320,
                        name: 'Override gate',
                        description: 'Be able to force driving gate',
                        value: false
                    },
                    {
                        permission: 330,
                        name: 'Recieve notices',
                        description: 'Get notified by email on gate events',
                        value: false
                    },
                    {
                        permission: 340,
                        name: 'Recieve warnings',
                        description: 'Get notified by email on gate warnings',
                        value: false
                    },
                    {
                        permission: 350,
                        name: 'View gate cycles',
                        description: 'Be able to view number of gate cycles.',
                        value: false
                    },
                    {
                        permission: 360,
                        name: 'View gate status',
                        description: 'Be able to view if gate is open or closed etc.',
                        value: false
                    },
                    {
                        permission: 370,
                        name: 'View gate log',
                        description: 'Be able to read past gate events',
                        value: false
                    },
                    {
                        permission: 380,
                        name: 'View gate users',
                        description: 'Be able to view who are connected to this aprticular gate',
                        value: false
                    },
                    {
                        permission: 390,
                        name: 'Manage gate info',
                        description: 'Be able to manage gate info',
                        value: false
                    },
                    {
                        permission: 400,
                        name: 'Manage gate invites',
                        description: 'Be able to manage gate invites',
                        value: false
                    },
                    {
                        permission: 410,
                        name: 'Manage gate users',
                        description: 'Be able to manage who are connected to this gate',
                        value: false
                    },
                    {
                        permission: 420,
                        name: 'Manage gate schedules',
                        description: 'Be able to manage schedules for this gate',
                        value: false
                    },
                    {
                        permission: 430,
                        name: 'Manage User Permissions',
                        description: 'Be able to change the authority of other users (these permissions)',
                        value: false
                    },
                    {
                        permission: 440,
                        name: 'Manage Subscription',
                        description: 'Be able to manage subscription level for this gate',
                        value: false
                    },

                ],
            }
        },
        methods: {
            cancel() {
                this.$root.$emit('bv::hide::modal', 'permission-modal');
            },
            updateUserGatePermissions() {
                gateService.updateUserGatePermissions({
                    UserId: this.gateUser.userId || this.gateUser.id,
                    GateId: this.gate.id,
                    permissionsList: this.defaultGateUserPermissions
                }).then((data) => {
                    //console.log('updateUserGatePermissions data', data);
                    this.$root.$emit('dmx::saved::gateUserPermissions');
                });
            },
            resetInAppAuthenticationCode() {
                accountService.resetInAppAuthenticationCode(this.gateUser.id)
                    .then((data) => {
                        this.makeToast('Gate user permissions', `Authentication code has been reset`, 'warning');
                    });
            },
            ...mapActions({
                hasGatePermission: 'authentication/hasGatePermission',
            }),
        },
        computed: {
            ...mapState({
                stateUser: store => store.authentication.user,
            })
        },
        watch: {
            
        },
        mounted() {
            this.defaultGateUserPermissions.map((x) => {
                let ugp = (this.gate.userGatePermissions.length) ? this.gate.userGatePermissions : this.gateUser.userGatePermissions
                ugp.map((y) => {
                    if (y == x.permission) x.value = true;
                });
            });
        }
    }
</script>